const initBurger = () => {
    const burger = {
        menu: document.querySelector('.js-burger-menu'),
        open: document.querySelector('.js-burger-open'),
        close: document.querySelector('.js-burger-close')
    }

    if (!burger.menu || !burger.open || !burger.close) return;

    const handleBurger = (display = true) => {
        if (display) {
            burger.menu.classList.remove('right-[-110%]');
            burger.menu.classList.add('right-0')
        } else {
            burger.menu.classList.remove('right-0')
            burger.menu.classList.add('right-[-110%]');
        }
    }

    burger.open.addEventListener('click', () => handleBurger(true));
    burger.close.addEventListener('click', () => handleBurger(false));
}

initBurger();
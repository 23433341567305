import domReady from '@roots/sage/client/dom-ready';
// import '../blocks/my-banner';
import './partials/home-slider';
import './partials/brands-slider';
import './partials/burger-menu';
import './partials/faq';

/**
 * Application entrypoint
 */
domReady(async () => {
  // ...
});

/**
 * @see {@link https://webpack.js.org/api/hot-module-replacement/}
 */
if (import.meta.webpackHot) import.meta.webpackHot.accept(console.error);
import Swiper from 'swiper';
import { Navigation, Pagination } from 'swiper/modules';

const swiperBrands = new Swiper('.brands', {
    modules: [Navigation, Pagination],
    loop: true,
    wrapperClass: 'brands-wrapper',
    slideClass: 'brands-slide',
    autoplay: {
        delay: 2000,
        disableOnInteraction: false,
        stopOnLastSlide: false
    },
    slidesPerView: 2,
    speed: '300',
    spaceBetween: 0,
    navigation: {
        nextEl: '.brands-button-next',
        prevEl: '.brands-button-prev',
    },
    breakpoints: {
        480: { slidesPerView: 3 },
        640: { slidesPerView: 4 }
    }
});
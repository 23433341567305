const faqs = document.querySelectorAll('.js-faq');

faqs.forEach(faq => {
    const faqItems = faq.querySelectorAll('.js-faq-elem');
    faqItems.forEach(faqItem => {
        faqItem.addEventListener('click', () => {
            faqItems.forEach(processingItems => {
                if (processingItems == faqItem) {
                    faqItem.classList.toggle('active');
                } else {
                    processingItems.classList.remove('active');
                }
            })
        });
    })
});
import Swiper from 'swiper';
import { Navigation, Pagination } from 'swiper/modules';


const swiperCarousel = new Swiper('.carousel', {
    modules: [Navigation, Pagination],
    loop: true,
    wrapperClass: 'carousel-wrapper',
    slideClass: 'carousel-slide',
    autoplay: {
        delay: 8000,
    },
    speed: '700',
    pagination: {
        el: '.carousel-pagination',
    },
    navigation: {
        nextEl: '.carousel-button-next',
        prevEl: '.carousel-button-prev',
    },
});